module.exports = [{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-manifest-virtual-33dfa771f8/0/cache/gatsby-plugin-manifest-npm-5.7.0-ef15e52c51-5c3a69fb9c.zip/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#803ed7","theme_color":"#803ed7","display":"minimal-ui","icon":"src/images/favicon/favicon-32x32.png","legacy":false,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"ee6b4abb450eff575971aff81df5f1d8"},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-gatsby-cloud-virtual-2cc8c866be/0/cache/gatsby-plugin-gatsby-cloud-npm-5.7.0-a7251cf547-cccc0db270.zip/node_modules/gatsby-plugin-gatsby-cloud/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../.yarn/unplugged/gatsby-virtual-75cb3a0312/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
